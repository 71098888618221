// src/components/Cart.js
import React from 'react';

function Cart() {
  return (
    <div>
      <h2>Shopping Cart</h2>
      {/* Cart items will be displayed here */}
      <button>Checkout</button>
    </div>
  );
}

export default Cart;