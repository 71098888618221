import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Volume2 } from 'lucide-react';
import './AdvertisementBanner.css';

const AdvertisementBanner = () => {
  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadAdvertisements();
  }, []);

  useEffect(() => {
    if (ads.length > 0) {
      const interval = setInterval(() => {
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [ads.length]);

  const loadAdvertisements = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/advertisements`);
      setAds(response.data);
    } catch (error) {
      console.error('Error loading advertisements:', error.response ? error.response.data : error.message);
    } finally {
      setIsLoading(false);
    }
  };
  

  if (isLoading) {
    return <div>Loading advertisements...</div>;
  }

  if (ads.length === 0) return null;

  const currentAd = ads[currentAdIndex];
  const isLink = !!currentAd.link;

  return (
    <div className={`ad-banner ${isLink ? 'ad-banner-link' : ''}`}>
      <div className="icon-container">
        <Volume2 size={20} color="#ffffff" />
      </div>
      <div className="marquee-container">
        <div className="marquee">
          {isLink ? (
            <a href={currentAd.link} target="_blank" rel="noopener noreferrer">
              {currentAd.content}
            </a>
          ) : (
            <span>{currentAd.content}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvertisementBanner;
