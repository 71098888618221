import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageCarousel.css';

const ImageCarousel = ({ images }) => {
  console.log('Received images in ImageCarousel:', images);

  if (!Array.isArray(images) || images.length === 0) {
    return <div>No images available</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <div className="image-carousel">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="carousel-slide">
            {image.link ? (
              <a href={image.link} target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.REACT_APP_API_URL}${image.url}`} alt={`Slide ${index + 1}`} />
              </a>
            ) : (
              <img src={`${process.env.REACT_APP_API_URL}${image.url}`} alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;